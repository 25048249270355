body{
  .MuiTabs-scroller{
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 20px;
  }
  .MuiTabs-scrollable{
    margin-bottom: 20px !important;
  }
  .MuiInputLabel-shrink{
    transform: translate(0, 1.5px) scale(0.90)
  }
  .MuiTextField-root{
    .MuiInputLabel-shrink{
      transform: translate(0, 1.5px) scale(0.90)
    }
  }
  .MuiTooltip-tooltip{
    font-size: 12px;
  }
  .MuiButton-containedPrimary, .MuiButton-outlinedPrimary{
    font-size: 13px;
  }
  @media only screen and (max-width: 667px){
    .MuiDialog-paper{
      margin: 8px !important;
    }
    .MuiTabs-scrollButtons{
      width: 20px !important;
    }
    .MuiDialog-paperWidthSm{
      min-height: 300px !important;
    }
  }
  @media only screen and (orientation: landscape){
    .MuiDialog-paper{
      margin: 8px !important;
      min-height: 320px !important;
    }
  }
}

@import "variables";
$details_padding: 9px 0;
*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.center {
  text-align: center;
  box-shadow: 0 2px 4px #00000047;
}
.header {
  margin:0;
  font-size: 18px;
  font-weight: 500;
  width:inherit;
  font-size: 18px;
  font-family: "Conv_IBMPlexSans-Regular";
  color: #BD10E0;
  background-color: #F5F7FA;
  padding: 10px 10px;
}
.Head {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  a:link,a:active,a:hover{
    text-decoration: none;
  }
  h3 {
    font-family: sans-serif;
    font-size: 12px;
    text-transform: uppercase;
  }
  .widget-cards {
    width: 19%;
    border-radius: 4px;
    .loader{
      min-height: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color:#fff;
      font-weight: 5.00;
    }
    .details-wrapper {
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      .owned,.managed{
        width: 48%;
        padding: 12px 0;
        border-radius: 4px;
      }
    }
  }
  .total-assets .ta-container{
    background: linear-gradient(90deg, #6542C1, #6189FF) !important;
    h3{
      color: #FCFCFC !important;
    }
  }
  .on-lease .ta-container{
    background-image: linear-gradient(90deg, #BEED87, #D2E9B6 95%) !important;
    h3{
      color: #5EC264 !important;
    }
  }
  .off-lease .ta-container{
    background-image: linear-gradient(269deg, #FFDFCB, #FFB98D 95%) !important;
    h3{
      color: #EE7C26 !important;
    }
  }
  .on-ground .ta-container{
    background-image: linear-gradient(269deg, #A7C4FF, #729CF1) !important;
    h3{
      color: #4C79D5 !important;
    }
  }
  .written-off .ta-container{
    background: linear-gradient(180deg, #fff, #F6F6F6 7%, #D7D7D7) !important;
    h3{
      color:#000 !important;
    }
  }
  .banner{
    border-radius: 4px;
    padding: 15px 0px;
    margin-bottom: 8px;
  }
}
.claims-section{
  .loader{
    width: 100%;
    height: 25vh;
    background-color: #fff;
    justify-content: center;
  }
  .MuiPaper-root{
    &>div{
      max-height: 427px;
    }
  }
}
.claims-side-card {
  // background-image: linear-gradient(270deg, #6538DB, #0A56A8);
  color: #FFFFFF;
  height: 100%;
  min-width: 30%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  .stats-data {
    width: 95%;
    margin-bottom: 20px;
    text-align: center;
    h6{
      font-size: 18px;
      font-family: "Conv_IBMPlexSans-Regular";
      color:#fff;
      font-weight: 500;
    }
    p{
      color:#f0a4ff;
      font-size:14px;
      font-family: "Conv_IBMPlexSans-Regular";
    }
  }
}
.upcoming-events{
  $heading-color : #bd10e0;
  $heading-font-size : 18px;
  $p-font-size : 12px;
  $font-weight : 500;
  text-align:left;
  h6{
    display: flex;
    font-weight: 500;
    @include elementStyles($size: 18px, $color: #bd10e0, $fontFamily: $ibmplexRegular);
    margin:10px 0 0 -15px;
    padding: 10px 15px;
    .export-btn{
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-size: 12px;
      color:rgb(63,81,181);
      cursor: pointer;
    }
  }
  .loader{
    width: inherit;
    height: 20vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .event-component{
    background-color: #fbfbfb;
    padding: 10px 10px 0px 10px;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
    border-radius: 0;
    h2{
      font-size: 14px;
      color : #4a4a4a;
      font-weight: 500;
      font-family: "Conv_IBMPlexSans-Regular";
      margin-bottom: 10px
    }
    .event-list{
      height: 600px;
      overflow: auto;
      margin-bottom: 10px;
      .event{
        margin: 1px 1px 20px;
        .MuiPaper-root {
          border-radius:0;
          .MuiAccordionSummary-root{
            padding: 0;
            .MuiAccordionSummary-content{
              margin:0;
              h4{
                font-size: 16px;
                font-family: "Conv_IBMPlexSans-Regular";
                padding: 9px;
                cursor: pointer;
                position: relative;
                font-weight: 500;
                line-height: 0.9rem;
                a{
                  font-size: 16px;
                  font-family: "Conv_IBMPlexSans-Regular";
                  color: #bd10e0;
                }
                span{
                  font-size: 12px;
                  color: #a1a1a1;
                }
                p{
                  font-size: 12px;
                  color: #a1a1a1;
                }
                .error-msg{
                  font-size: 12px;
                  color: #ff0000;
                }
              }
            }
          }
          .MuiCollapse-container{
            .MuiAccordionDetails-root{
              padding: 0;
              .main{
                width:100%;
                & > div{
                  ul{
                    padding-left: 0;
                    list-style: none;
                    li{
                      margin-bottom: 10px;
                      h6{
                        font-size: 12px;
                        font-family: "Conv_IBMPlexSans-Regular";
                        color: #4a4a4a;
                        margin-bottom: 4px;
                        padding: 0 15px;
                      }
                      p{
                        font-size: 14px;
                        font-family: "Conv_IBMPlexSans-Regular";
                        color: #4a4a4a;
                        font-weight: 500;
                      }
                    }
                  }
                  ul,li{
                    padding: 0;
                    margin: 0;
                    border: none;
                    float: none;
                    outline: 0;
                  }
                }
                .main-info{
                  padding:9px;
                }
                .main-other{
                  padding: 11px;
                  background-color: #f5f8fa;
                }
              }
            }
          }
        }
      }
    }
  }
}

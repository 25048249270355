@import 'variables';
body{
  .engine-workscope-module-table{
    .border-right{
      border-right: 1px solid #dee2e6;
    }
    .border-left{
      border-left: 1px solid #dee2e6;
    }
    .workscope-table-heading{
      background: #f0f2f3;
      border: 1px solid #dfe3e6;
      margin: 0;
      overflow: visible;
      h6{
        padding: 6px 6px;
        @include elementStyles($size: 12px, $color: #6d9bb9, $fontFamily: $ibmplexMedium);
      }
      .workscope-level{
        h6{
          border-bottom: 1px solid #dfe3e6;
          text-align: center;
        }
      }
    }
    .engine-workscope-table{
      background: #fff;
      .add-new-row{
        @include elementStyles($size: $font13, $fontFamily: $ibmplexMedium, $color: #3f51b5);
        border: 1px solid #3f51b5;
        padding: 3px 6px;
        display: inline-block;
        border-radius: 3px;
        cursor: pointer;
      }
      .workscope-row{
        border-bottom: 1px solid #d7d7d7;
        .module-col{
          position: relative;
          .module-content{
            padding: 15px;
          }
        }
        .workscope-sub-row{
          border-bottom: 1px solid #d7d7d7;
          &:last-child{
            border-bottom: none;
          }
          .submodule-col{
            .submodule-content{
              padding: 15px;
            }
          }
          .workscope-item-row{
            border-bottom: 1px solid #d7d7d7;

            .item-col{
              .item-content{
                width: 100%;
                padding: 15px;
              }
              &.workscope-level{

              }
            }

          }
        }
      }
    }
  }
  .maintenance-claims{
    table{
      tr{
        cursor: pointer;
      }
    }
  }
  .maintenance-module-section{
    margin-top: 60px;
    .maintenance-heads{
      margin-bottom:15px;
      h3{
        @include elementStyles($size: $font20, $fontFamily: $ibmplexRegular, $color: #bd10e0, $margin: 0 0 5px 0);
      }
      h4{
        @include elementStyles($size: $font16, $fontFamily: $ibmplexRegular, $color: #bd10e0, $margin: 0 0 5px 0);
      }
      p{
        @include elementStyles($size: $font14, $fontFamily: $ibmplexRegular, $color: rgba(0, 0, 0, 0.54));
      }
    }
    .no-upcoming-events-card{
      background: #FFFFFF;
      .upcoming-events-card-body{
        padding: 40px;
        h4{
          @include elementStyles($size: $font18, $fontFamily: $ibmplexLight, $color: #ffae42, $margin: 0 0 20px 0);
        }
        ul{
          li{
            @include elementStyles($size: $font14, $fontFamily: $ibmplexRegular, $color: #000000, $margin: 0 0 6px 0);
            &::before{
              content: '- '
            }
          }
        }
      }
    }
    .assembly-claim-list{
      margin-bottom: 20px;
      h4{
        display: inline-block;
        @include elementStyles($size: $font20, $fontFamily: $ibmplexLight, $color: #000000);
      }
      button{
        margin-left: auto;
      }
    }
    .assembly-desc{
      font-size: 14px;
      color: #7a7a7a;
      position: absolute;
    }
    .title-info {
      margin-bottom: 20px;
      .arrow-icon {
        float: left;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
      h4{
        display: inline-block;
        font-size: 22px;
        font-family: $ibmplexLight !important;
        font-weight: 200;
      }
      p{
        font-size: 14px;
        color: #BD10E0;
        font-family: $ibmplexRegular;

      }
    }
    .mr-claim {
      font-family: $ibmplexRegular;
      font-size: 20px;
      font-weight: 200;
    }
    .shop-visit-list{
      // margin-top: 20px;
      .add-sv-cta{
        margin-bottom: 20px;
        float: right;
      }
      table{
        tbody{
          tr{
            td{
                .status{
                  font-size: 13px;
                  padding: 6px;
                  border-radius: 4px;
                  text-align: center;
                  width: 100px;
                  &.draft{
                    background-color: #d2f0d8;
                  }
                  &.in-review{
                    border: 1px solid #d19d01;
                    background-color: #ffbf00;
                  }
                  &.approved{
                    border: 1px solid #5cb603;
                    background-color: #83ea1f;
                  }
                  &.rejected{
                    border: 1px solid #b52607;
                    background-color: #f44a25;
                    color: #ffffff;
                  }
                  &.submitted{
                    border: 1px solid #0d28bf;
                    background-color: #3f51b5;
                    color: #ffffff;
                  }
                .workscope-label{
                  @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #877201);
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
      .workscope-wrapper{
        position: relative;
        background: #f5f7fa;
        border: 1px solid #c6cddd;
        border-radius: 3px;
        .run-type, .total-cost{
          background: #f0f2f3;
          padding: 15px;
          border-bottom: 1px solid #d9d9d9;
          h4{
            @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
            margin-bottom: 10px;
          }
        }
        .workscopes-block, .performance-block, .llp-block{
          background: #fff;
          padding: 15px;
          border-bottom: 1px solid #d9d9d9;
          h4{
            @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
            margin-bottom: 10px;
          }
        }
      }
    }
    .add-mr-claim{
      margin-bottom: 20px;
      float: right;
    }
    .add-engine-llp {
      margin-bottom: 20px;
      float: right;
    }
    .mnt-event-card{
      margin-bottom: 40px;
      position: relative;
      .edit-sv{
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 99;
        color: #2F3F9F;
        cursor: pointer;
      }
      .content-info{
        padding: 25px 15px 15px 15px;
        &.payout-claim-info{
          background: #f7f3dd;
          border-top: 1px solid #ddd4a6;
          position: relative;
        }
        table{
          background: #faf4e7;
          padding: 5px;
          border-radius: 4px;
          tr{
            td{
              &:first-child{
                @include elementStyles($size: $font12, $fontFamily: $ibmplexRegular, $color: #212529);
              }
              padding: 3px;
            }
          }
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    .upcoming-shop-visit{
      span{
        @include elementStyles($size: $font16, $fontFamily: $ibmplexRegular, $color: #9439e4);
        display: inline-block;
        margin-bottom: 8px;
      }
      .shopvisit-row{
        margin-bottom: 30px;
        padding: 10px;
        background: #FFFFFF;

        label{
          @include elementStyles($size: $font12, $fontFamily: $ibmplexRegular, $color: #dfd700);
        }
        h4{
          @include elementStyles($size: $font16, $fontFamily: $ibmplexRegular, $color: #000000);
        }
        h6{
          @include elementStyles($size: $font14, $fontFamily: $ibmplexRegular, $color: #000000);
        }
        p{
          @include elementStyles($size: $font13, $fontFamily: $ibmplexRegular, $color: #7a7a7a);
          margin-bottom: 6px;
        }
      }
    }
    .no-records-found{
      text-align: center;
      background-color: #f5f5fd;
      position: relative;
      border: 1px dashed #c4c4c4;
      border-radius: 4px;
      min-height: 400px;
      .inner-wrapper{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .claim-text{
          @include elementStyles($size: $font16, $fontFamily: $ibmplexMedium, $color: #000000);
          margin-bottom: 10px;
        }
        .add-mr-claim{
          float: none;
        }
      }
    }
  }
  .asset-listing-section{
    .asset-list-heading{
      color: #bd10e0;
      font-family: $ibmplexRegular;
      margin-bottom: 4px;
      font-size: 16px;
    }
    .asset-list-description{
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      margin-bottom: 5px;
    }
    .add-asset-cta{
      float: right;
    }
    .status {
      font-size: 13px;
      padding: 6px;
      border-radius: 4px;
      text-align: center;
      width: 100px;
      &.on-lease {
        background: #beed87;
        color: #5ec264;
      }
      &.off-lease {
        background: #ffb98d;
        color: #ee7c26;
      }
      &.on-ground {
        background: #729cf1;
        color: #4c79d5;
      }
      &.sold{
        background: linear-gradient(to bottom, #d7d7d7 0%, #f6f6f6 95%, white 100%);
        color: #000000;
      }
    }
    .asset-export{
      float: right;
      margin-bottom: 5px;
    }
    ul{
      margin-top: 3px;
      li{
        &.portfolio-type{
          background-color: #56b4da;
          color: #ffffff;
          border-radius: 4px;
          padding: 2px 10px;
          margin-right: 3px
        }
        svg{
          font-size: 18px;
          vertical-align: middle;
        }
      }
    }
  }
  .MuiDialog-paper{
    .data-upload-modal{
      .data-upload-instructions{
        margin-bottom: 15px;
        .info-area{
          padding: 10px;
          border-radius: 3px;
          color: #856404;
          background-color: #fff3cd;
          border-color: #ffeeba;
        }
        .alert-msg{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexMedium, $color: #000000);
        }
      }
      .dropzone-area{
        width: 500px;
        position: relative;
        margin: 0 auto;
        .drag-drop-cnt{
          .MuiDropzoneArea-textContainer{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .MuiDropzoneArea-text{
              margin-top: auto;
            }
            .MuiDropzoneArea-icon{
              color: #3f51b5;
            }
          }
        }
      }
    }
  }
}
